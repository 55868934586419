<template>
    <div class="columns">
        <div class="content body-content" v-if="contentStatus">
            <div class="panel panel-body">
                <div class="mb-12">
                    <p class="message-date">{{text.message_date}}: {{messageData.messageDate | formatDateTime}}</p>
                    <span v-html="messageData.body"></span>
                </div>
                <router-link :to="{ name: 'UserMessagesView', params: { id : unreadMessage.message.id } }" :title="text.view + ' ' + unreadMessage.message.headline" class="btn" v-if="unreadMessage.total > 0">{{text.view_next_unread_message}}</router-link>
            </div>
        </div>
    </div>
</template>

<script>
    import {log} from "@/shared/utils";
    import lang from "@/shared/lang";

    import {formatContentMixin} from "@/views/mixins/formatContent";
    import {messagesActionsMixin} from "@/views/mixins/messagesActions";

    import {mapActions, mapState} from "vuex";

    export default {
        name: 'UserMessagesView',
        mixins: [formatContentMixin, messagesActionsMixin],
        data() {
            return {
                messageData: {
                    messageDate: "2020-01-01T01:00:01"
                },
                contentStatus: true,
            }
        },
        computed: {
            ...mapState(['pageTitle']), // imports from store
            text() {
                return lang;
            },
            id() {
                return this.$route.params.id;
            }
        },
        created() {
            this.triggerLoadMessageData();
        },
        methods: {
            ...mapActions(["setPageTitleAction"]),
            async triggerLoadMessageData() {
                let result = await this.loadUserMessageData(this.id);

                log(["Result of page data load", result]);

                if (result.status === 200) {
                    this.messageData = result.data.data;

                    // Set Page Title
                    this.setPageTitleAction({ title : this.pageTitle.title.replace("%s%", this.messageData.headline) });

                    // make sure if this message is marked unread
                    if(await this.markMessageAsRead(this.id))
                    {
                        // are there any more unread messages?
                        this.loadUserNextUnreadMessage();
                    }

                } else {
                    // hide page content
                    this.contentStatus = false;

                    // Set Page Title
                    this.setPageTitleAction({ title : this.text.content_404 });
                }
            }
        },
        watch:{
            $route (){
                this.triggerLoadMessageData();
            }
        }
    }
</script>
