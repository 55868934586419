<template>
    <div>
        <div class="table-scroller table-shadow">
            <table>
                <thead>
                <tr>
                    <th class="w-7/12">{{text.headline}}</th>
                    <th class="w-3/12">{{text.published}}</th>
                    <th class="w-2/12">{{text.actions}}</th>
                </tr>
                </thead>
                <tbody v-if="activeTableData.length > 0">
                <tr v-for="row in activeTableData" :key="row.id">
                    <td><router-link :to="{ name: 'UserMessagesView', params: { 'id' : row.id } }" :title="text.view + ' ' + row.headline" class="default-action" :class="{'unread': !row.read}">{{row.headline}}</router-link></td>
                    <td>{{row.messageDate | formatDate}}</td>
                    <td>
                        <ViewAction :routeName="'UserMessagesView'" :routeParams="{ 'id' : row.id }" :linkName="row.headline" />
                    </td>
                </tr>
                </tbody>
                <tbody v-else>
                <tr>
                    <td colspan="3">{{text.no_results}}</td>
                </tr>
                </tbody>
            </table>
        </div>
        <Pagination v-if="activeTablePagination.totalPages > 0" :pagination="activeTablePagination" @go-previous="refreshMessagesList" @go-next="refreshMessagesList" />
    </div>
</template>

<script>
    import lang from "@/shared/lang";

    import {formatContentMixin} from "@/views/mixins/formatContent";
    import {messagesActionsMixin} from "@/views/mixins/messagesActions";

    import Pagination from "@/components/pagination";
    import ViewAction from "@/components/actionlinks/view";

    export default {
        name: 'UserMessagesList',
        components: {Pagination, ViewAction},
        mixins: [formatContentMixin,messagesActionsMixin],
        data() {
            return {
                activeTableData: [],
                activeTablePagination: [],
            }
        },
        computed: {
            text() {
                return lang;
            },
        },
        methods: {
            async refreshMessagesList(page = 1){
                await this.loadUserActiveMessagesList(page);
            }
        },
        async mounted() {
            this.refreshMessagesList(1);
        }
    }
</script>
