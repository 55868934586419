<template>
    <div>
        <div class="table-scroller">
            <table class="archive">
                <thead>
                <tr>
                    <th class="w-7/12">{{text.headline}}</th>
                    <th class="w-3/12">{{text.published}}</th>
                    <th class="w-2/12">{{text.actions}}</th>
                </tr>
                </thead>
                <tbody v-if="archiveTableData.length > 0">
                <tr v-for="row in archiveTableData" :key="row.id">
                    <td><router-link :to="{ name: 'AdminMessagesView', params: { 'id' : row.id } }" :title="text.edit + ' ' + row.headline" class="default-action">{{row.headline}}</router-link></td>
                    <td class="table-meta">{{row.messageDate | formatDate}}</td>
                    <td>
                        <ViewAction :routeName="'AdminMessagesView'" :routeParams="{ 'id' : row.id }" :linkName="row.headline" />
                    </td>
                </tr>
                </tbody>
                <tbody v-else>
                <tr>
                    <td colspan="3">{{text.no_results}}</td>
                </tr>
                </tbody>
            </table>
        </div>
        <Pagination v-if="archiveTablePagination.totalPages > 0" :pagination="archiveTablePagination" @go-previous="refreshMessagesList" @go-next="refreshMessagesList" />
    </div>
</template>

<script>
    import lang from "@/shared/lang";
    import {MESSAGE_TYPE_SUCCESS} from "@/shared/consts";

    import {formatContentMixin} from "@/views/mixins/formatContent";
    import {messagesActionsMixin} from "@/views/mixins/messagesActions";

    import Pagination from "@/components/pagination";
    import ViewAction from "@/components/actionlinks/view";

    export default {
        name: 'AdminMessagesArchive',
        components: {Pagination, ViewAction},
        mixins: [formatContentMixin,messagesActionsMixin],
        data() {
            return {
                archiveTableData: [],
                archiveTablePagination: [],
            }
        },
        computed: {
            text() {
                return lang;
            },
            message() {
                return this.$route.params.message
            }
        },
        methods: {
            async refreshMessagesList(page = 1){
                await this.loadAdminArchiveMessagesList(page);
            },
            showMessage() {
                if(this.message === "saved"){
                    this.$store.dispatch('addSystemMessageAction', {
                        'type' : MESSAGE_TYPE_SUCCESS,
                        'message' : lang.message_message_edit_saved
                    });
                }
                else if(this.message === "added"){
                    this.$store.dispatch('addSystemMessageAction', {
                        'type' : MESSAGE_TYPE_SUCCESS,
                        'message' : lang.message_message_added
                    });
                }
            },
            messageDeleted(){
                this.$store.dispatch('addSystemMessageAction', {
                    'type' : MESSAGE_TYPE_SUCCESS,
                    'message' : lang.message_message_deleted
                });
                this.refreshMessagesList(1);
            }
        },
        async mounted() {
            this.refreshMessagesList(1);
            this.showMessage();
        }
    }
</script>
